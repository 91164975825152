var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-row',{staticClass:"pt-4 mx-2 pt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","dense":"","item-value":"id","item-text":"username","items":_vm.topup_list,"label":"Select TopUp"},model:{value:(_vm.selectedTopUp),callback:function ($$v) {_vm.selectedTopUp=$$v},expression:"selectedTopUp"}})],1),_c('v-col',{staticClass:"text-left mt-0 pt-0",attrs:{"cols":"5","xs":"5"}},[_c('v-text-field',{attrs:{"dense":"","type":"date","label":"Start Date"},model:{value:(_vm.selectedStartDate),callback:function ($$v) {_vm.selectedStartDate=$$v},expression:"selectedStartDate"}})],1),_c('v-col',{staticClass:"text-right mt-0 pt-0",attrs:{"cols":"5","xs":"5"}},[_c('v-text-field',{attrs:{"dense":"","type":"date","label":"End Date"},model:{value:(_vm.selectedEndDate),callback:function ($$v) {_vm.selectedEndDate=$$v},expression:"selectedEndDate"}})],1),_c('v-col',{staticClass:"text-right mt-0 pt-0",attrs:{"cols":"2","xs":"2"}},[(_vm.loading)?_c('spinner',{staticClass:"text-end"}):_c('v-btn',{staticClass:"primary",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.getRequestedDeliveries()}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-search"}})],1)],1)],1),_c('v-card-title',{},[_c('span',{staticClass:"me-3"},[_vm._v("("+_vm._s(_vm.requestedDeliveries.length)+") Deliveries")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.requestedDeliveries,"item-key":"id","items-per-page":10,"disable-sort":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"cursor":"pointer"}},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.client))]),_c('small',[_vm._v(_vm._s(item.shipping_town)+"-"+_vm._s(item.shipping_location))])])]}},{key:"item.total_cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_paid)+" / "+_vm._s(item.total_cost)+" FCFA ")]}},{key:"item.deliverer_username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deliverer_username)+" ")]}},{key:"item.delivery_confirmed",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[item.delivery_confirmed]},on:{"click":[function($event){$event.stopPropagation();_vm.setDialog=true},function($event){return _vm.changeSwitch(item.id)}]}},[(item.delivery_confirmed)?_c('span',[_vm._v("Delivered")]):_c('span',[_vm._v("Pending")])])]}},{key:"item.client_contact",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":'https://api.whatsapp.com/send?phone='+item.client_contact+'/&text=Hello *'+item.client+'* 😃, i hope you are doing great 💫 today. %0a I\'m a PettyCash core team member, and am glad to bring you the following on your order. You currently have an order with:- %0a Order ID: *'+item.order_id+'*, %0a Ordered on: *'+item.get_date_str+'*, %0a Payments Through: *'+item.payment_means+'*, %0a Cost: *'+item.total_cost+'*, %0a Owing: *'+item.total_owing+'*. %0a You can ask any questions concerning this order of yours here.',"target":"_blank"}},[_c('v-btn',{staticClass:"success",attrs:{"elevation":"6","icon":"","small":""}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-comments"}})],1)],1)]}},{key:"item.get_date_str",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.get_date_str)+" ")]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"width":"500px"},model:{value:(_vm.setDialog),callback:function ($$v) {_vm.setDialog=$$v},expression:"setDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:" white--text"},[_vm._v(_vm._s(_vm.dialogTitle))]),_c('v-card-text',{staticClass:"black--text pt-2"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.deliveryDetails)}}),_c('v-switch',{attrs:{"label":"Delivered","color":"success","inset":""},on:{"click":function($event){return _vm.changeValidateState()}},model:{value:(_vm.validatedState),callback:function ($$v) {_vm.validatedState=$$v},expression:"validatedState"}}),_c('v-switch',{attrs:{"label":"Pending","color":"primary","inset":""},on:{"click":function($event){return _vm.changePendingState()}},model:{value:(_vm.pendingState),callback:function ($$v) {_vm.pendingState=$$v},expression:"pendingState"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeDeliveryState()}}},[_vm._v(" Change ")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){_vm.setDialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-row class="pt-4 mx-2 pt-0">
          <v-col
            cols="12"
          >
            <v-select
              v-model="selectedTopUp"
              outlined
              dense
              item-value="id"
              item-text="username"
              :items="topup_list"
              label="Select TopUp"
            ></v-select>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-left mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedStartDate"
              dense
              type="date"
              label="Start Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-right mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedEndDate"
              dense
              type="date"
              label="End Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
            xs="2"
            class="text-right mt-0 pt-0"
          >
            <spinner v-if="loading" class="text-end"></spinner>
            <v-btn
              v-else
              icon small
              class="primary"
              @click="getRequestedDeliveries()"
            >
              <font-awesome-icon icon="fa-solid fa-search"/>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-title class="">
          <span class="me-3">({{ requestedDeliveries.length }}) Deliveries</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="requestedDeliveries"
          item-key="id"
          class="table-rounded"
          :items-per-page="10"
          disable-sort
          :search="search"
        >
          <!-- name -->
          <template #[`item.client`]="{item}">
            <div class="d-flex flex-column" style="cursor: pointer">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.client }}</span>
              <small>{{ item.shipping_town }}-{{ item.shipping_location }}</small>
            </div>
          </template>

          <template #[`item.total_cost`]="{item}">
            {{ item.total_paid}} / {{ item.total_cost}} FCFA
          </template>

          <template #[`item.deliverer_username`]="{item}">
             {{ item.deliverer_username }}
          </template>

          <!-- status -->
          <template #[`item.delivery_confirmed`]="{item}">
            <v-chip
              small
              :color="statusColor[item.delivery_confirmed]"
              class="font-weight-medium"
              @click.stop="setDialog=true"
              @click="changeSwitch(item.id)"
            >
              <span v-if="item.delivery_confirmed">Delivered</span>
              <span v-else>Pending</span>
            </v-chip>
          </template>

          <template #[`item.client_contact`]="{item}">
            <a 
                :href="'https://api.whatsapp.com/send?phone='+item.client_contact+'/&text=Hello *'+item.client+'* 😃, i hope you are doing great 💫 today. %0a I\'m a PettyCash core team member, and am glad to bring you the following on your order. You currently have an order with:- %0a Order ID: *'+item.order_id+'*, %0a Ordered on: *'+item.get_date_str+'*, %0a Payments Through: *'+item.payment_means+'*, %0a Cost: *'+item.total_cost+'*, %0a Owing: *'+item.total_owing+'*. %0a You can ask any questions concerning this order of yours here.'" 
                target="_blank"
                style="text-decoration: none"
            >
              <v-btn class="success" elevation="6" icon small>
                <font-awesome-icon icon="fa-solid fa-comments"/>
              </v-btn>
            </a>
          </template>
          <template #[`item.get_date_str`]="{item}">
            {{ item.get_date_str }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-dialog width="500px" v-model="setDialog"> 
        <v-card> 
          <v-card-title class=" white--text">{{ dialogTitle }}</v-card-title> 
          <v-card-text class="black--text pt-2">
            <span v-html="deliveryDetails"></span>

            <v-switch label="Delivered" color="success" inset v-model="validatedState" @click="changeValidateState()"></v-switch>
            <v-switch label="Pending" color="primary" inset v-model="pendingState" @click="changePendingState()"></v-switch>
            <v-btn color="primary" @click="changeDeliveryState()" > Change </v-btn> 
          </v-card-text> 
          <v-divider></v-divider> 
          <v-card-actions> 
            <v-spacer></v-spacer> 
            <v-btn color="success" text @click="setDialog = false" > Close </v-btn> 
          </v-card-actions> 
        </v-card> 
    </v-dialog> 
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      true: 'primary',
      Validated: 'success',
      false: 'error',
    }

    return {
      headers: [
        { text: 'Client', value: 'client' },
        { text: 'Paid', value: 'total_cost' },
        { text: 'Assigned To', value: 'deliverer_username' },
        { text: 'Delivery Status', value: 'delivery_confirmed' },
        { text: 'Chat', value: 'client_contact' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      selectedTopUp: "",
      topup_list: [],
      requestedDeliveries: [],
      selectedStartDate: null,
      selectedEndDate: null,

      loading: false,
      statusColor,
      search: '',
      setDialog: false,
      selectedDelivery: null,
      dialogTitle: "Set Delivery Status",
      deliveryDetails: "",
      pendingState: false,
      validatedState: false,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: MarketPlace | View Deliveries"
      this.getTopUps()
  },

  methods:{
    getTopUps(){
      axios
        .get('/api/v1/manager/get/topups/')
        .then(response => {
          this.topup_list = response.data  // get the data and fill into campaigns
          console.log(this.topup_list)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    changeSwitch(delivery_id){
      axios
        .get('/api/v1/manager/market_place/get/requested_delivery/'+delivery_id+'/')
        .then(response => {
          const username = response.data[0]["client_username"]
          this.selectedDelivery = delivery_id
          const shipping_location = response.data[0]["shipping_location"]

          this.deliveryDetails = "Change the state of this delivery to <b>'"+username+"'</b> at  <b>'"+shipping_location+"'</b>"
          console.log(response.data)

          this.pendingState = response.data[0]["pending_state"]
          this.validatedState = response.data[0]["validated_state"]
        })
        .catch(error => {
          this.setDialog = false
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    changeValidateState(){
      if(this.validatedState){
        this.pendingState = false
      }
    },
    
    changePendingState(){
      if(this.pendingState){
        this.validatedState = false
      }
    },

    changeDeliveryState(){
      if (!this.pendingState && !this.validatedState){
        this.$store.commit('setSnackBarMessage', "Error: Please select a state")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
      }
      else{
        let formData = new FormData()
        formData.append('pendingState', this.pendingState)
        formData.append('validatedState', this.validatedState)

        axios
          .post('/api/v1/manager/market_place/change/requested_delivery/'+this.selectedDelivery+'/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => {
            this.$store.commit('setSnackBarMessage', response.data[0].text)
            this.$store.commit('setSnackBarColor', "primary darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            this.getRequestedDeliveries()
            this.setDialog = false
          })
          .catch(error => {
            this.setDialog = false
            if (error.response){
              this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            }else{
              this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
            }
          })
      }
    },

    async getRequestedDeliveries(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }
      if (this.selectedTopUp === ''){
        this.selectedTopUp = 'All'
      }
      if (!this.selectedTopUp || this.selectedTopUp === ''){
        this.selectedTopUp = 'All'
      }
      this.loading = true
      console.log(this.loading)
      await axios
        .get('/api/v1/manager/market_place/get/deliveries/'+this.selectedTopUp+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this.requestedDeliveries = response.data  // get the data and fill into campaigns
          console.log(this.requestedDeliveries)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
          }
        })
      this.loading = false
    },
  },
}
</script>
